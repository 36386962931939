import type { Database } from '@/types/database.types';

export function useSettings() {
  const supabase = useSupabaseClient<Database>();

  return useAsyncData('settings', async () => {
    const { data } = await supabase.from('settings').select().single();

    return data;
  });
}
